import { PortableText } from '@portabletext/react';
import Grid from '@mui/material/Grid';
import portableTextComponents from '../portableComponents';
import styled$ from 'utils/styledTransient';
import theme from 'theme';

const StyledGrid = styled$(Grid)<{
  $mobileDirection?: string
}>(({
  $mobileDirection,
}) => `
${theme.breakpoints.down('lg')} {
  flex-wrap: ${$mobileDirection === 'row' ? 'nowrap' : 'wrap'};
  overflow: auto; 
  width: 100%;
}
${theme.breakpoints.down('md')} { 
  width: ${$mobileDirection === 'row' ? '110%' : '100vw'};
  padding-right: ${$mobileDirection === 'row' ? '30px' : 'none'};
}
`);

const StyledGridItem = styled$(Grid)<{
  $mobileDirection?: string
}>(({
  $mobileDirection,
}) => `
${theme.breakpoints.down('md')} {
  min-width: ${$mobileDirection === 'row' ? '20%' : 'auto'};
  justify-content: center;
}
${theme.breakpoints.down('sm')} {
  min-width: ${$mobileDirection === 'row' ? '60%' : 'auto'};
  padding-left: ${$mobileDirection === 'row' ? '20px' : 'auto'};
  padding-right: ${$mobileDirection === 'row' ? '20px' : 'auto'};
  justify-content: ${$mobileDirection === 'row' ? 'center' : 'left'};
}
`);

const GridWidgetRenderer = ({
  value: {
    xs,
    md,
    lg,
    cells,
    mobileDirection,
  },
}) => {
  return <div>
    <StyledGrid container spacing={3} $mobileDirection={mobileDirection}>
      { cells?.map((cell, index) => <StyledGridItem
        key={index}
        item
        xs={xs}
        md={md}
        lg={lg}
        $mobileDirection={mobileDirection}
      >
        {cell?.content && <PortableText
          value={cell.content}
          components={portableTextComponents}
        />
        }
      </StyledGridItem>)}
    </StyledGrid>
  </div>;
};

export default GridWidgetRenderer;

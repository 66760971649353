import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { SyntheticEvent } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from './sanity/sanityClient';

/**
 * Get the URL of the thumbnail of specified size. In case of failure, just return the original URL.
 *
 * @param {string} originalUrl the url stored in Firestore
 * @param {number} width image width in px
 * @param {number} height image height in px
 * @return {string} image url
 */
export const getImageThumbUrl = (originalUrl: string, width: number, height: number): string => {
  try {
    const indexOfImageName = originalUrl.indexOf('products%2F') + 11;
    const indexOfExtension = originalUrl.indexOf('.jpg');
    const startingPart = originalUrl.slice(0, indexOfImageName);
    const middlePart = originalUrl.slice(indexOfImageName, indexOfExtension);
    const endingPart = originalUrl.slice(indexOfExtension);
    if (indexOfImageName < 0 || indexOfExtension < 0) {
      throw Error();
    }
    return `${startingPart}thumbnails%2F${middlePart}_${width}x${height}${endingPart}`;
  } catch {
    return originalUrl;
  }
};

/**
 * Provide a fallback image in case of the failure to load the required image
 * @param {SyntheticEvent<HTMLImageElement, Event>} e onerror event
 * @param {string} fallbackUrl absolute url of the fallback image
 */
export const provideFallbackImage = (e: SyntheticEvent<HTMLImageElement, Event>, fallbackUrl: string): void => {
  const target = e.target as HTMLImageElement;
  if (target.src !== fallbackUrl) {
    target.onerror = null;
    target.src = fallbackUrl;
  }
};

const builder = imageUrlBuilder(sanityClient);

/**
 * Resolve Sanity Image URL
 * @param {SanityImageSource} source  Sanity image source object.
 * @return {string}                   URL for the image.
 */
export const urlForImage = (source: SanityImageSource) => {
  return builder.image(source);
};

import { urlForImage } from 'utils/imageUtils';
import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme';

const ImageContainer = styled.div`
  ${theme.breakpoints.down('sm')} {
    gap: ${theme.spacing(1)};
    width: 100%;
    margin: ${theme.spacing(3, 0)};
  }
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(3)};
  margin: ${theme.spacing(5, 0)};
`;

const Image = styled.img<{
  imageOrientation?: string
}>(({
  imageOrientation,
}) => `
  object-fit: contain;
  width: 100%;
  margin: auto;
  max-height: ${imageOrientation === 'portrait' ? '960px' : 'auto'}
`);

const ImageTextContainer = styled.div`
  width: 100%;
`;

const ImageText = styled(Typography)`
  ${theme.breakpoints.down('sm')}{
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0px;
  }
  font-size: 0.9375rem !important;
  line-height: 1.3125rem;
  letter-spacing: 0.07px;
`;

const BlogImageWidgetRenderer = ({
  value: {
    image,
    alt,
    imageText,
    imageOrientation,
  },
}) => {
  const width = useMemo(() => {
    if (typeof window === 'undefined') {
      return 1200;
    }
    return window.innerWidth;
  }, []);

  if (!image) {
    return null;
  }
  return (
    <ImageContainer>
      <Image
        imageOrientation={imageOrientation}
        src={
          urlForImage(image)
            .width(width)
            .url()}
        alt={alt}
      />
      <ImageTextContainer>
        <ImageText>
          {imageText}
        </ImageText>
      </ImageTextContainer>
    </ImageContainer>
  );
};
export default BlogImageWidgetRenderer;

import styled, { CreateStyled } from '@emotion/styled';

const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};

/**
 * A wrapper for EmotionJs styled to make styled-component like transient param support. All params
 * starting with dollar sign will not be included into the html dom element.
 * @param  {any} component  React component
 * @return {JXS.Element}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styled$ = (component: any) => styled(component, transientOptions);

export default styled$;

/* eslint-disable @typescript-eslint/no-explicit-any */

const VideoWidgetRenderer = ( {
  value: {
    video,
    // alt,
  },
}) => {
  if (!video) {
    return null;
  }
  return (
    <video>
      <source
        src={''}
        type='video/mp4'
      />
    </video>
  );
};

export default VideoWidgetRenderer;

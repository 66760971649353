import { PortableText } from '@portabletext/react';
import portableTextComponents from '../portableComponents';

const RichTextWidgetRenderer = (props) => {
  return <div>
    <PortableText
      value={props.value.content}
      components={portableTextComponents}
    />
  </div>;
};

export default RichTextWidgetRenderer;

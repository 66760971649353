import * as React from 'react';
import { PortableTextReactComponents } from '@portabletext/react';
import BlogImageWidgetRenderer from './renderers/blogImageWidgetRenderer';
import GridWidgetRenderer from './renderers/GridWidgetRenderer';
import HeroWidgetRenderer from './renderers/HeroWidgetRenderer';
import ImageWidgetRenderer from './renderers/ImageWidgetRenderer';
import RichArrayTextWidgetRenderer from './renderers/RichArrayTextRenderer';
import RichTextWidgetRenderer from './renderers/RichTextWidgetRenderer';
import VideoWidgetRenderer from './renderers/VideoWidgetRenderer';
import slugs from 'utils/slugs';

const portableTextComponents: Partial<PortableTextReactComponents> = {
  /**
   * List all widget renderers here in types. This will map, Sanity's widget type
   * to correct React component to render it.
   */
  types: {
    ['grid-widget']: GridWidgetRenderer,
    ['hero-widget']: HeroWidgetRenderer,
    ['image-widget']: ImageWidgetRenderer,
    ['blog-image-widget']: BlogImageWidgetRenderer,
    ['rich-text']: RichTextWidgetRenderer,
    ['rich-array-text']: RichArrayTextWidgetRenderer,
    ['video-widget']: VideoWidgetRenderer,

    callToAction: ({
      value, isInline,
    }) =>
      isInline ?
        <a href={value.url}>{value.text}</a> :
        <div className="callToAction">{value.text}</div>
    ,
  },

  marks: {

    internalLink: (data) => {
      try {
        const {
          value, children,
        } = data;

        const internalRef = value?.reference?._ref;
        if (!internalRef) {
          // TODO: think proper error handling
          return <>
            {children};
          </>;
        }

        const slug = slugs[internalRef];
        if (!slug) {
          // TODO: think proper error handling
          return <>
            {children};
          </>;
        }
        const href = `/${slug}`;
        return (
          <a href={href}>
            {children}
          </a>);
      } catch (error) {
        console.error({
          error,
          _ref: data.value?.reference?._ref,
        });
      }
    },
    link: ({
      children, value,
    }) => {
      const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

export default portableTextComponents;
